/*
 * 共通関数
 * コンポーネントで使用するにはmixinsで読み込む
 * import cf from '@/mixins/commonFunctions';
 * mixins: [cf],
 */

import moment from 'moment';
import { cloneDeep } from 'lodash';
import axios from '../plugins/axios';

export default {
  /**
   * localStorage[${key}]の値を返却
   * @param  str key localStorageの対象キー
   * @return obj jsonパース済みオブジェクト
   */
  getLocalStorage(key) {
    let result;
    if (localStorage[key]) {
      result = JSON.parse(localStorage[key]);
    }
    return result;
  },


  /**
   * ローカルストレージに保存
   * @param obj 既存データにマージしたいオブジェクト
   * @param str key localStorageの対象キー
   */
  saveLocalStorage(obj, key = '') {
    if (!key) return;
    const ls = this.getLocalStorage(key);
    const save = ls ? { ...ls, ...obj } : obj;
    const stringify = JSON.stringify(save);
    localStorage.setItem(key, stringify);
  },


  /**
   * localStorage削除
   * @param key localStorageから削除するキー
   */
  deleteLocalStorage(key, key2 = null) {
    if (!key) return;
    if (localStorage[key]) {
      if (!key2) {
        localStorage.removeItem(key);
        console.log(`localStorage[${key}]を削除しました`);
      } else {
        // 特定の子階層のみを削除

        // 指定キー以外を一時ストック
        const stock = {};
        const target = JSON.parse(localStorage[key]);

        const keys = Object.keys(target);
        for (let i = 0; i < keys.length; i += 1) {
          const k = keys[i];
          if (k !== key2) stock[k] = target[k];
        }
        // 一度localstorage[key]ごと削除して
        localStorage.removeItem(key);
        // ストックしておいたkey2の除外データを保存
        this.saveLocalStorage(stock, key);
        console.log(`localStorage[${key}]の[${key2}]を削除しました`);
      }
    } else {
      console.log(`localStorage[${key}]は存在しません`);
    }
  },


  /**
   * 文字列判定
   * @param value
   * @return bool
   */
  isString(inputText) {
    return (typeof inputText === 'string' || inputText instanceof String);
  },


  /**
   * 数値判定（NaNやInfinityは無効）
   * @param value
   * @return bool
   */
  isNumber(value) {
    return Number.isFinite(value);
  },


  /**
   * 整数値の判定
   * @param str
   * @return bool
   */
  checkInteger(str) {
    if (!str) return false;
    return String(str).match(/^[+|-]?[0-9]+$/);
  },


  /**
   * 整数値か小数値の判定
   * @param str
   * @return bool
   */
  checkFloat(str) {
    if (!str) return false;
    return str.match(/^[-|+]?[0-9]*\.[0-9]+$|^[+|-]?[0-9]+$/);
  },


  /**
   * valueが整数値に変更可能かどうか
   * @param value
   * @return bool
   */
  convert2Num(str) {
    if (!str) return null;
    if (!this.checkInteger(str)) return null;
    const result = Number(str);
    return this.isNumber(result) ? result : null;
  },


  /** URLからページ番号取得 */
  getPageNum(route) {
    const query = route.query;
    const page = this.convert2Num(query.page);
    return page || 1;
  },


  /**
   * ページネーション配列の生成
   * @param object args
   *   currentPage
   *   lastPage
   * @return array
   *
   * パターン1
   *   合計ページ数が5未満
   * パターン2
   *   1 2 3 4 ... 50
   * パターン3
   *   1 ... 48 49 50
   * パターン4
   *   1 ... 3 4 5 ... 50
   */
  generatePagination(args) {
    const result = [];
    const last = args.lastPage;
    const prevNext = last > 5;

    // 1ページ完結の場合はスルー
    if (last === 1) return [];

    // prevボタン
    if (prevNext) {
      result.push({
        class: 'prev',
        icon: 'fal fa-angle-left',
        page: args.currentPage - 1,
      });
    }

    // パターン毎にpager作成
    const start = [1, 2, 3];
    const end = [(last - 2), (last - 1), last];
    const num = 4;
    if (!prevNext) {
      for (let i = 0; i < last; i += 1) {
        result.push({ page: i + 1 });
      }
    } else if (start.includes(args.currentPage)) {
      // パターン1
      for (let i = 0; i < num; i += 1) {
        result.push({ page: i + 1 });
      }
      result.push(
        { dot: true },
        { page: last },
      );
    } else if (end.includes(args.currentPage)) {
      // パターン2
      result.push(
        { page: 1 },
        { dot: true },
      );
      for (let i = 0; i < 4; i += 1) {
        result.push({ page: last - (num - i - 1) });
      }
    } else {
      // パターン3
      result.push(
        { page: 1 },
        { dot: true },
        { page: args.currentPage - 1 },
        { page: args.currentPage },
        { page: args.currentPage + 1 },
        { dot: true },
        { page: last },
      );
    }

    // nextボタン
    if (prevNext) {
      result.push({
        class: 'next',
        icon: 'fal fa-angle-right',
        page: args.currentPage + 1,
      });

      // 現ページが最初か最後の場合はdisabledクラス追加
      if (args.currentPage === 1) result[0].class = 'prev disabled';
      if (args.currentPage === last) result[result.length - 1].class = 'next disabled';
    }

    return result;
  },

  /**
   * user_idからuser情報を返却
   */
  async getGuestUser(id) {
    const response = await axios({
      method: 'GET',
      url: '/v1/user/get/byId',
      params: { id },
    });
    return response.data.user;
  },

  /**
   * 環境に応じてstripeのpublicKeyを返却
   */
  getStripePubKey() {
    let STRIPE_PUB_KEY = null;
    switch (process.env.NODE_ENV) {
      case 'production':
        STRIPE_PUB_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY_PROD;
        break;
      default:
        STRIPE_PUB_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY_DEV;
        break;
    }
    return STRIPE_PUB_KEY;
  },

  /**
   * DBが準備できるまでyamlからプロジェクトと
   * リターンを返却
   */
  getProjectDetailFromYaml(slugs, projects) {
    if (!slugs.project || !slugs.return) return;
    const result = {
      project: null,
      return: null,
    };

    projects.some((row) => {
      if (slugs.project === row.slug) {
        // プロジェクト特定
        result.project = row;
        // リターンの特定
        row.returns.some((r) => {
          if (slugs.return === r.slug) {
            result.return = r;
            return true;
          }
          return false;
        });
        return true;
      }
      return false;
    });
    return result;
  },


  /**
   * 【重要】
   * コンポーネントで直接使用するには
   * methodsオブジェクトに入れておく必要がある
   */
  methods: {
    /** タイムスタンプのフォーマット */
    formatTimestamp(stamp, format = 'YYYY-MM-DD') {
      let result = moment(stamp, 'X').format(format);
      if (!String(stamp).includes('GMT')) {
        result = moment(stamp).format(format);
      }
      return result;
    },

    /** 日付の差分（引数ー本日） */
    getRemainingToday(target) {
      const today = moment(new Date());
      const limit = moment(target);
      const remainingDays = limit.diff(today, 'days') > -1
        ? limit.diff(today, 'days')
        : 0;
      return remainingDays;
    },

    /** targetまでの残り時間を秒数で返す  */
    getRemainingSeconds(target) {
      const current = moment(new Date());
      const limit = moment(target);
      const diffSeconds = limit.diff(current, 'seconds');
      return diffSeconds;
    },

    /** 残り時間が1秒以上あるかどうか  */
    isRemaining(target) {
      const diffSeconds = this.getRemainingSeconds(target);
      return diffSeconds > 0;
    },

    /** 残り時間に最適な数値を返す ※1秒以上残っているtargetに使う想定 */
    getRemainingTimeNum4Display(target) {
      const diffSeconds = this.getRemainingSeconds(target);
      let timeNum;
      if (diffSeconds > 60 * 60 * 24) {
        timeNum = `${this.addComma(Math.floor(diffSeconds / (60 * 60 * 24)))}`;
      } else if (diffSeconds > 60 * 60) {
        timeNum = `${Math.floor(diffSeconds / (60 * 60))}`;
      } else if (diffSeconds > 60) {
        timeNum = `${Math.floor(diffSeconds / 60)}`;
      } else { // 1分未満は"1分"と表示する
        timeNum = '1';
      }
      return timeNum;
    },

    /** 残り時間に最適な単位を返す ※1秒以上残っているtargetに使う想定 */
    getRemainingTimeUnit4Display(target) {
      const diffSeconds = this.getRemainingSeconds(target);
      let timeUnit;
      if (diffSeconds > 60 * 60 * 24) {
        timeUnit = '日';
      } else if (diffSeconds > 60 * 60) {
        timeUnit = '時間';
      } else { // 1分未満は"1分"と表示する
        timeUnit = '分';
      }
      return timeUnit;
    },

    /** クローンオブジェクトの返却 */
    getClone(object) {
      return cloneDeep(object);
    },

    /**
     * アイコンURL（type = 2）の返却
     * @param obj object
     *   ユーザ等特定idのオブジェクト
     */
    getMyIconUrl(object) {
      const defaultIcon = '/img/default/icon.png';
      let url = defaultIcon;

      if (!object) return url;

      const keys = Object.keys(object);
      if (!keys.includes('urls')) return url;

      const isArray = Array.isArray(object.urls);
      if (isArray) {
        // adjustUrls未適用（[{}, {}, ...]）
        const urls = object.urls || [];

        if (urls.length) {
          // item.type = 2（アイコン）画像を返却する
          urls.some((item) => {
            if (item.type === 2) {
              url = item.url || defaultIcon;
              return true;
            }
            return false;
          });
        }
      } else if (object.urls && object.urls.icon.length) {
        // adjustUrls適用済み（{ main: {}, icon: {}, ... }）
        url = object.urls.icon[0].url || defaultIcon;
      }

      return url;
    },

    /**
     * ユーザオブジェクトから表示ラベルを返却
     * // 1. usernameが存在する場合は優先
     * // 2. usernameなくfacebook.nameが存在する場合はfacebook.name
     * // 3. username・facebook.nameともにない場合はemail
     */
    getUserLabel(user) {
      let name = user.email;
      if (user.username) name = user.username;
      return name;
    },

    /**
     * アイキャッチ取得
     * data.eyecatchの有無でデフォ画像と振り分け
     */
    getEyecatch(data) {
      return data.eyecatch || '/img/default/default.jpg';
    },

    /** 文字列の中からimgタグとhtmlタグを除去 */
    replaceImgTag(str) {
      let result = str;
      if (str) {
        // imgタグをsrc丸ごと削除
        const images = str.match(/<img(.|\s)*?>/gi);
        if (images && images.length) {
          images.forEach((img) => {
            result = result.replace(img, '');
          });
        }
        // テキスト文中からhtmlタグを削除
        result = result
          .replace(/(<([^>]+)>)/gi, '')
          .replace(/\n/g, '')
          .replace(/&nbsp;/g, '');
      }
      return result;
    },

    /**
     * 3桁毎にカンマ割り振り
     * @param int num 元の数値
     */
    addComma(num, afterPoint = '', count = 0) {
      if (num !== 0 && !num) return '';
      // if (!this.isNumber(num)) return false;

      // 文字列にする
      let strNum = String(num);
      let afterDecimalPoint = afterPoint;
      if (strNum.includes('.')) {
        // 小数点以下を含む場合
        const split = strNum.split('.');
        strNum = split[0];
        afterDecimalPoint = `.${split[1]}`;
      }
      const len = Number(strNum.length);

      // 再帰的に呼び出し
      if (len > 3) {
        // 前半を引数に再帰呼び出し + 後半3桁
        strNum = `${this.addComma(strNum.substring(0, len - 3), afterDecimalPoint, count + 1)},${strNum.substring(len - 3)}`;
      }

      if (count === 0) {
        strNum += `${afterDecimalPoint}`;
      }

      return `${strNum}`;
    },

    /**
     * 小数点以下の計算をする際に
     * ずれが生じてしまうため、
     * 文字列かして小数点の位置を判別
     */
    getDotPosition(value) {
      // 数値のままだと操作できないので文字列化
      const strVal = String(value);
      let dotPosition = 0;

      // 小数点が存在するか確認
      if (strVal.lastIndexOf('.') !== -1) {
        // 小数点があったら位置を取得
        dotPosition = (strVal.length - 1) - strVal.lastIndexOf('.');
      }

      return dotPosition;
    },

    /**
     * 小数点が存在する数値の合計値計算
     * @param value1  対象1
     * @param value2  対象2
     * @param formula 式
     *   addition 足し算
     *   subtract 引き算
     *   multiply 掛け算
     *   divide   割り算
     */
    decimalCalculation(value1, value2, formula = 'addition') {
      // それぞれの小数点の位置を取得
      const dotPosition1 = this.getDotPosition(value1);
      const dotPosition2 = this.getDotPosition(value2);

      // 位置の値が大きい方（小数点以下の位が多い方）の位置を取得
      const max = Math.max(dotPosition1, dotPosition2);

      // 大きい方に小数の桁を合わせて文字列化、
      // 小数点を除いて整数の値にする
      const intValue1 = parseInt((value1.toFixed(max)).replace('.', ''), 10);
      const intValue2 = parseInt((value2.toFixed(max)).replace('.', ''), 10);

      // 10^N の値を計算
      const power = 10 ** max;

      // 整数値で計算した後に10^Nで割る
      let result;
      switch (formula) {
        case 'addition': // 足し算
          result = (intValue1 + intValue2) / power; break;
        case 'subtract': // 引き算
          result = (intValue1 - intValue2) / power; break;
        case 'multiply': // 掛け算
          result = (intValue1 * intValue2) / power; break;
        case 'divide': // 割り算
          result = (intValue1 / intValue2) / power; break;
        default:
          result = (intValue1 + intValue2) / power; break;
      }
      return result;
    },

    /**
     * 小数点n位で四捨五入
     * @param value
     */
    roundN(value, n) {
      if (!value) return 0;
      const N = 10 ** n;
      return Math.round(value * N) / N;
    },

    /**
     * value1/valu2ををパーセンテージで返却
     * 小数点2位以下を四捨五入
     * @param value1, value2
     */
    getPercentage(value1, value2) {
      const decimalValue = this.decimalCalculation(value1, value2, 'divide');
      return this.roundN(decimalValue * 100, 2);
    },

    /**
     * filter群
     */
    filterDate(date) {
      return moment(date).format('YYYY.M.D');
    },
    filterDate_ja(date) {
      return moment(date).format('YYYY年M月D日');
    },
    filterDatetime(date) {
      return moment(date).format('YYYY.M.D HH:mm');
    },
    filterNumber_format(value) {
      // valueを文字列に変換
      const str = String(value);
      if (!str.match(/^\d+$/)) {
        return value;
      }
      const formatter = new Intl.NumberFormat('ja-JP');
      return formatter.format(str);
    },
    filterTax_price(value, rate) {
      let includeTax = value * (rate + 1);
      // 小数点以下切り捨て
      includeTax = Math.round(includeTax);
      return includeTax;
    },
    filterZip(value) {
      const str = value ? String(value) : null;
      let result = '';
      if (str && str.includes('-')) {
        // ハイフンが含まれている場合はそのまま出力
        result = `〒${str}`;
      } else if (str && !str.includes('-')) {
        // ハイフンを付与して出力
        result = `〒${str.substr(0, 3)}-${str.substr(3, 4)}`;
      }
      return result;
    },
    filterRemove_hyphen(value) {
      const str = value.replace(/-/g, '');
      return str;
    },
    filterExcerpt(value, limit = 30) {
      let str = value;
      if (str) {
        str = value.replace(/\r\n|\r|\n/g, '').replace(/(<([^>]+)>)/gi, '');
        str = str.length > limit ? `${str.slice(0, limit)}...` : str;
      }

      return str;
    },
    filterAgeGroup(ages, n) {
      // agesは引数でマスターデータを受け取る
      if (!n) return;
      let result = n;
      ages.some((age) => {
        if (age.value === n) {
          result = age.label;
          return true;
        }
        return false;
      });
      return result;
    },

    /** DB値から支払い方法名へ変換 */
    convPaytypeVal2Label(val) {
      let result;
      switch (val) {
        case 1: result = 'クレジットカード'; break;
        case 2: result = '銀行振込'; break;
        default: result = null; break;
      }
      return result;
    },
    /** 支払い方法名からDB値へ変換 */
    convPaytypeLabel2Val(label) {
      let result;
      switch (label) {
        case 'クレジットカード': result = 1; break;
        case '銀行振込': result = 2; break;
        default: result = null; break;
      }
      return result;
    },
  },
};
