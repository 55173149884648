<template>
  <div id="reset-password" class="reset-password accounts">
    <div class="container-sm">
      <h2 class="title-page mb-4">パスワードリセット</h2>

      <div
        class="wrap-error"
        v-if="invalid">
        <p>アクティベートキーが無効です。<br>再度パスワード再設定リクエストを送信してください。</p>
        <p><router-link
          to="/forgot-password/"
          class="link">再設定リクエスト</router-link></p>
      </div>

      <form
        class="form vertical"
        v-if="!invalid"
        v-on:submit="submit"
        autocomplete="off">
        <div class="form-contents">
          <dl class="form-group">
            <dt class="label">
              <label
                for="password"
                class="form-label">パスワード</label>
            </dt>
            <dd class="detail input">
              <div class="form-parts">
                <input
                  id="password"
                  type="password"
                  name="password"
                  v-model.trim="$v.password.$model"
                  v-bind:class="{ input_error: validationFlags.password }"
                  v-on:input="invalidsCheck('password', $v.password.$invalid)"
                  >
              </div>

              <p
                class="form-text"
                v-if="$v.password.$dirty
                  && $v.password.required.$invalid">パスワードを入力してください</p>

              <p
                class="form-text"
                v-if="$v.password.$dirty
                  && $v.password.minLength.$invalid">パスワードは8文字以上で設定してください</p>
            </dd>
          </dl>

          <dl class="form-group">
            <dt class="label">
              <label
                for="repeatPassword"
                class="form-label">パスワードの確認</label>
            </dt>
            <dd class="detail input">
              <div class="form-parts">
                <input
                  id="repeatPassword"
                  type="password"
                  name="repeatPassword"
                  v-model.trim="$v.repeatPassword.$model"
                  v-bind:class="{ input_error: validationFlags.repeatPassword }"
                  v-on:input="invalidsCheck('repeatPassword', $v.repeatPassword.$invalid)"
                  >
              </div>

              <p
                class="form-text"
                v-if="$v.repeatPassword.$dirty
                  && $v.repeatPassword.sameAsPassword.$invalid">新しいパスワードと同一のパスワードを入力してください。</p>
            </dd>
          </dl>

        </div>

        <div class="form-group btns mt-sm">
          <ul class="btn-lists">
            <li>
              <button
                type="submit"
                class="btn btn__primary sm"
                v-bind:disabled="!submitFlag">送信</button>
            </li>
          </ul>

          <div class="messages mt-4">
            <p
              v-if="!flag.sended">新しいパスワードを入力・送信してください。</p>

            <p
              v-if="flag.sended
                && flag.expired
                && !flag.updated">パスワード再設定の有効期限が切れています。再度<router-link to="/forgot-password/" class="link">こちら</router-link>からパスワード再設定リクエストをお送りください。</p>

            <p
              v-if="flag.sended
                && !flag.expired
                && !flag.updated">パスワードアップデートのためのアクティベートキーが無効です。<br>再度<router-link to="/forgot-password/" class="link">こちら</router-link>からパスワード再設定リクエストをお送りください。</p>

            <p
              v-if="flag.sended
                && flag.updated">パスワードを更新しました。<br><router-link to="/login/?isResetPass=true" class="link">こちら</router-link>からログインしてください。</p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, sameAs } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'reset-password',
  mixins: [cf],
  data() {
    return {
      validationFlags: {
        password: false,
        repeatPassword: false,
      },
      // 無効フラグ
      invalids: {
        password: true,
        minLength: true,
        repeatPassword: true,
      },
      // パラメータなし等で無効
      invalid: true,
      activatekey: null,
      flag: {
        sended: false,
        updated: false,
        expired: false,
      },
    };
  },
  created() {
    // パラメータが含まれているか確認
    const query = this.$route.query;
    if (query.secret) {
      this.invalid = false;
      this.activatekey = query.secret;
    }
  },
  setup() {
    const password = ref('');
    const repeatPassword = ref('');

    const rules = {
      // パスワードは必須で8文字以上
      password: {
        required,
        minLength: minLength(8),
      },
      repeatPassword: {
        sameAsPassword: sameAs(password),
      },
    };

    const $v = useVuelidate(rules, { 
      password,
      repeatPassword,
    });

    return { 
      password,
      repeatPassword,
      $v,
    };
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.password 
        && !this.invalids.repeatPassword
        && !this.flag.updated 
        && !this.flag.expired;
    },
  },
  methods: {
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },
    submit(e) {
      e.preventDefault();
      // validateチェックは入力時に行われてる
      const data = {
        flag: 1,
        password: this.password,
        activatekey: this.activatekey,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/resetPassword',
        data,
      })
        .then((response) => {
          const resData = response.data;
          if (resData.updated) this.flag.updated = true;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.expired) this.flag.expired = true;
          } else console.log(error);
        })
        .finally(() => {
          this.flag.sended = true;
        });
    },
  },
};
</script>
