<template>
  <div class="container container-sm">
    <div class="loader-wrapper">
      <div class="loaded-contents" v-if="!flag.loader">
        <div :class="s.title">継続支援一覧</div>
        <ul :class="s.list">
          <li
            v-for="(prod, i) in products"
            :key="i">
            <router-link
              :class="s.content"
              :to="`/subscriptions/${productMasterData(prod.id).name}/`">
              <div class="flex-grow-1">
                <div class="fw-bold">{{ prod.name }}</div>
                <div>{{ prod.description }}</div>
                <div class="mt-3">
                  <ul :class="s.plans">
                    <li
                      v-for="(price, j) in prod.prices.data"
                      :key="j">
                      <div>{{ price.nickname }}｜¥{{ addComma(price.unit_amount * ( 1 + prod.tax.percentage / 100)) }}</div>
                      </li>
                  </ul>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="loader-simple"
        v-if="flag.loader"></div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';


export default {
  name: 'subscription',
  mixins: [cf],
  data() {
    return {
      products: [],
      flag: {
        loader: true,
      },
    };
  },
  created() {
    this.getSubscriptionData();
  },
  computed: {
    ...mapState(['user', 'helper']),
    prodIds() {
      if (!this.helper.master.subscriptions?.length) return [];
      return this.helper.master.subscriptions.map((prod) => prod.product_id[this.helper.env.name]);
    },
  },
  watch: {
    prodIds() {
      this.getSubscriptionData();
    },
  },
  methods: {
    productMasterData(pid) {
      return this.helper.master.subscriptions?.find((p) => p.product_id[this.helper.env.name] === pid) || null;
    },

    getSubscriptionData() {
      const params = {
        type: 'subscription',
        productIds: this.helper.master.subscriptions?.map((prod) => prod.product_id[this.helper.env.name]) || [],
      };
      this.axios({
        method: 'GET',
        url: 'v1/stripe/product/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.products = res.products;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loader = false;
        });
    },
  },
};
</script>

<style lang="scss" module="s">
.title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
}

.list {
  $mgn: 12px;
  > li {
    width: 100%;
    min-width: 200px;
    &:not(:first-child) {
      margin-top: 32px;
    }
  }
}

.content {
  display: block;
  color: #000000;
  border-radius: 12px;
  padding: 32px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  transition: all .3s;
  &:hover {
    opacity: .4;
  }
}

.plans {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-bottom: -8px;
  > li {
    padding: 4px 16px;
    margin-right: 8px;
    margin-bottom: 8px;
    background-color: #f0f8ff;
    border-radius: 48px;
    font-size: 12px;
  }
}
</style>
