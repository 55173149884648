<template>
  <rounded-box gutter='sm' radius='sm' :has-shadow="true">
    <div :class="$style.thumb">
      <img
        :class="$style.image"
        :src="sortItemImage(product.master.id)"
        :alt="item.nickname">
    </div>

    <p :class="$style.price">
      {{ taxIncludes ?
        addComma(filterTax_price(item.unit_amount, product.stripe.tax.percentage / 100)) :
        addComma(item.unit_amount) }}
      <span :class="$style.priceUnit">円</span>
      <small :class="$style.exTax">（税{{ taxIncludes ? '込': '抜' }}）</small>
    </p>

    <div :class="$style.title">{{item.nickname}}</div>

    <div
      class="n2br"
      :class="$style.description">
      <p v-if="item.metadata.description" v-html="item.metadata.description"></p>
    </div>

    <div
      v-if="salesTotal"
      :class="$style.meta">
      <div :class="$style.patron">支援者 {{ addComma(salesTotal.lot) }}<span>人</span></div>
    </div>

    <button
      v-if="showBtn && salesTotal"
      :class="$style.button"
      :disabled="isDisabled(item.id)"
      @click="submit">継続支援{{ isDisabled(item.id) ? '済み' : 'する' }}</button>
  </rounded-box>
</template>

<script>
import { mapState } from 'vuex';
import { RoundedBox } from '@/components/parts/Container';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'Subscriptionitem',
  mixins: [cf],
  components: {
    RoundedBox,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    showBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    taxIncludes: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      salesTotal: null,
      disabled: false,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    this.getSubscriptionSalesData();
  },
  methods: {
    /** サブスク商品（stripe管理）の合計額取得 */
    getSubscriptionSalesData() {
      this.axios({
        method: 'GET',
        url: '/v1/sales/get/subscription/sum',
        params: {
          price_id: this.item.id,
        },
      })
        .then((response) => {
          const res = response.data;
          this.salesTotal = res.result;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /** 支払いの実行 */
    submit() {
      const ref = { path: this.$router.options.history.location };
      cf.saveLocalStorage({ loginRef: JSON.stringify(ref) }, 'fk');

      // カード登録・支払いへ進む際の引数
      const data = {
        price: this.item,
        tax: this.product.stripe.tax,
        product: this.product.stripe.data,
        taxIncludes: this.taxIncludes,
      };

      if (!this.user.login.isLogin) {
        const args = {
          modalName: 'loginOnly',
          data: {
            title: 'サブスクリプションのお申し込み',
            description: 'アカウントをお持ちの方はログインページよりログインをお願いします。<br>アカウントをお持ちでない方は無料会員登録から作成してください。',
          },
        };
        this.$store.dispatch('modal/messages/showModal', args, { root: true });
        return;
      }
      if (!this.user.cards.cards.length) {
        const args = {
          modalName: 'formSubscription',
          data,
        };
        this.$store.dispatch('modal/contents/showModal', args, { root: true });
      } else {
        const args = {
          modalName: 'paymentSubscription',
          data,
        };
        this.$store.dispatch('modal/contents/showModal', args, { root: true });
      }
    },

    /** 該当のサブスクを支援済みか判定 */
    isDisabled(priceId) {
      return this.user.subscriptions.planIds.includes(priceId);
    },

    /** db(yaml)のid毎に画像振り分け */
    sortItemImage(productId) {
      // 画像の命名規則としてid/main.jpgとする
      return `/img/subscriptions/${productId}/main.jpg`;
    },
  },
};
</script>

<style module lang="scss">
.thumb {
  position: relative;
  display: block;
  width: calc(100% + 16px * 2);
  margin-left: -16px;
  margin-top: -16px;
  overflow: hidden;
}

.thumb::before {
  content: '';
  position: relative;
  display: block;
  width: 100%;
  padding-top: 55%;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.price {
  display: block;
  font-size: 32px;
  line-height: 1;
  font-weight: 700;
}

.thumb + .price {
  margin-top: 16px;
}

.priceUnit {
  margin-left: 8px;
  font-size: 24px;
}

.exTax {
  color: rgba(black, .64);
  font-size: 1.4rem;
}

.title {
  margin-top: 20px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}

.description {
  margin-top: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 15px;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.64);
}

.descriptionImage {
  margin-top: 1em;
}

.meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.patron,
.remaining {
  white-space: pre;
}


.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  margin-top: 16px;
  border: none;
  border-radius: 24px;
  background-color: #000;
  color: #fff;
  appearance: none;
  box-shadow: none;
  font-size: 18px;
  font-weight: bold;
  &:disabled {
    background-color: rgba(0,0,0,0.2);
  }
}

@media screen and (max-width: 767px) {
  .description {
    font-size: 14px;
  }
}
</style>
