<template>
  <div id="mainContents_wrapper" :class="$style.wrapper">
    <contents-container>
      <div :class="$style.columnContainer">
        <div :class="$style.main">
          <ul role="tablist" :class="$style.tabs">
            <li
              v-for="tab in tabs"
              :key="tab.name"
              :class="$style.tab"
              role="presentation">
              <button
                :class="$style.tab__trigger"
                :aria-controls="`panel-${tab.name}`"
                role="tab"
                :aria-selected="isActive === tab.name"
                @click="updateActiveTab(tab.name)"
              >
                {{tab.value}}
                <span
                  v-if="tab.notification"
                  :class="$style.tab__notification"
                  >{{ tab.notification }}</span>
              </button>
            </li>
          </ul>
          <div
            v-for="tab in tabs"
            :key="tab.name"
            :class="$style.tabPanel"
            :id="`panel-${tab.name}`"
            :aria-labelledby="`tab-${tab.name}`"
            :aria-hidden="tab.name !== isActive"
            role="tabpanel"
          >
            <component v-show="tab.name === isActive" :is="`${tab.name}-tab`" :project="project" />
          </div>
        </div>
        <div :class="$style.aside" ref="returns" id="returnList">
          <h3 :class="$style.aside__title">リターン</h3>
          <ul>
            <li
              v-for="returnItem in sortReturnItems"
              :key="returnItem.title"
              :class="$style.returnListItem"
              v-show="returnItem && returnItem.flag < 900">
              <p
                v-if="!returnItem.displayValidity"
                :class="$style.returnItemCourse">{{returnItem.course}}</p>
              <return-item
                v-if="!returnItem.displayValidity"
                :project="project"
                :return-item="returnItem"
                :isDonation="project.isDonation"
                :taxIncludes="project.taxIncludes" />
              <div v-if="returnItem.displayValidity">
                <p
                v-if="this.$store.state.user.email === returnItem.displayValidity.showUser"
                  :class="$style.returnItemCourse">{{returnItem.course}}</p>
                <return-item
                  v-if="this.$store.state.user.email === returnItem.displayValidity.showUser"
                  :return-item="returnItem"
                  :isDonation="project.isDonation"
                  :taxIncludes="project.taxIncludes" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </contents-container>
    <scroll-to-returns :target="$refs.returns" :is-shown="!isInviewReturns" />
  </div>
</template>

<script>
import { ContentsContainer } from '@/components/parts/Container';
import { ReturnItem } from '@/components/parts/Project';
import ScrollToReturns from './ScrollToReturns.vue';
import { CommentTab, IntroTab, NewsTab } from './TabContents';

export default {
  name: 'MainContents',
  props: ['project'],
  components: {
    ContentsContainer,
    ScrollToReturns,
    ReturnItem,
    CommentTab,
    IntroTab,
    NewsTab,
  },
  created() {
    this.pickUpDisableItems(); // returnItemsのうち支援不可能なものを調べる
  },
  computed: {
    sortReturnItems() {
      // 支援可能なリターンのみを抽出してソート
      const sortedAbledReturnItems = this.returnItems
        .filter((item) => !this.disabledItems.some((disabledItem) => disabledItem.id === item.id))
        .sort((itemA, itemB) => itemA.id - itemB.id);
      // 支援不可能なリターンもソート
      const sortedDisabledReturnItems = this.disabledItems.slice().sort((itemA, itemB) => itemA.id - itemB.id);
      // 支援可能 → 支援不可能 の順となるように連結
      const sortedReturnItems = sortedAbledReturnItems.concat(sortedDisabledReturnItems);

      return sortedReturnItems;
    },
  },
  methods: {
    updateActiveTab(name) {
      this.isActive = name;
    },
    setIntersectionObserver() {
      const options = {
        rootMargin: '0px 0px -50% 0px',
      };
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(({ isIntersecting }) => {
          this.isInviewReturns = isIntersecting;
        });
      }, options);
      const returns = this.$refs.returns;
      this.observer.observe(returns);
    },
    unobserve() {
      this.isInviewReturns = true;
      const returns = this.$refs.returns;
      this.observer.unobserve(returns);
    },
    async checkAvailable(item) {
      await this.axios({
        method: 'GET',
        url: '/v1/sales/get/sum',
        params: {
          type: 'return',
          id: item.id,
        },
      })
        .then((response) => {
          // 支援不可能ならdisabledItemsに追加
          if (((item.limit - response.data.result.lot) < 1) || item.disabled) {
            this.disabledItems.push(item);
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    async pickUpDisableItems() {
      this.returnItems.forEach((item) => this.checkAvailable(item));
    },
  },
  mounted() {
    this.setIntersectionObserver();
  },
  beforeUnmount() {
    this.unobserve();
  },
  data() {
    return {
      tabs: [
        {
          name: 'intro',
          value: 'プロジェクト内容',
        },
        {
          name: 'news',
          value: '新着情報',
          notification: 0,
        },
        {
          name: 'comment',
          value: '応援コメント',
        },
      ],
      isActive: 'intro',
      returnItems: this.project.returns,
      disabledItems: [],
      observer: null,
      isInviewReturns: false,
    };
  },
};
</script>

<style module>
.wrapper {
  margin-top: 80px;
  background-color: #fff;
}

.columnContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.main {
  flex-basis: 720px;
  flex-shrink: 1;
}

.tabs {
  display: flex;
  flex-wrap: nowrap;
}

.tab {
  display: block;
  width: 100%;
}

.tab__trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 9px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.4);
  transition: all 300ms ease;
}

.tab__trigger[aria-selected=true] {
  border-bottom-color: #000;
  color: #000;
}

.tab__notification {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  min-width: 20px;
  margin-left: 8px;
  padding: 0 3px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  background-color: #f95430;
  border-radius: 20px;
}

.tabPanel {
  margin-top: 40px;
}

.tabPanel[aria-hidden="true"] {
  display: none;
}

.tabPanel[aria-hidden="false"] {
  display: block;
}

.aside {
  flex-basis: 320px;
  padding-left: 40px;
  flex-shrink: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.aside__title {
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
}

.returnListItem {
  margin-top: 64px;
}

.returnItemCourse {
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .wrapper {
    margin-top: 24px;
  }

  .columnContainer {
    display: block;
  }

  .aside {
    margin-left: 0;
    margin-top: 32px;
    padding-left: 0;
    padding-top: 32px;
    border-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  .tab__trigger {
    font-size: 12px;
  }

  .tab__notification {
    font-size: 10px;
  }

  .tabPanel {
    margin-top: 24px;
  }

  .returnListItem {
    margin-top: 32px;
  }
}

</style>
