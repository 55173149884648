<template>
  <section>
    <div class="news-tab_content" :class="$style.content">
      <ul class="news-list">
        <li
          v-for="info in information"
          v-bind:key="info.id">
          <p class="news-date">{{ filterDate(info.updated_at) }}</p>
          <p>{{ info.title }}</p>
          <p class="news-description" v-if="info.description" v-html="info.description"></p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import cf from '@/mixins/commonFunctions';

export default {
  name: 'NewsTab',
  mixins: [cf],
  props: ['product'],
  data() {
    return {
      information: [],
    };
  },
  created() {
    this.information = this.product.master.informations;
    // TODO: APIから読み込みたい
    // this.getInformation();
  },
  methods: {
    getInformation() {
      const params = {
        product_id: this.product.id,
      };

      this.axios({
        method: 'GET',
        url: '/v1/information/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.resInformation;
          this.information = [];
          this.information = res;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {

        });
    },
  },
};
</script>

<style module>
.comingsoon {
  margin-top: 30px;
  font-size: 30px;
  text-align: center;
  font-style: italic;
}

@media screen and (max-width: 576px) {
  .comingsoon {
    font-size: 23px;
  }
}

@media screen and (max-width: 369px) {
  .comingsoon {
    font-size: 21px;
  }
}
</style>
