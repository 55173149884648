<template>
  <div class="home">
    <div class="home__top" ref="homeTop">
      <img src="../assets/images/top_hero.jpg" alt="" class="home__top__hero">
      <div class="home__top__title">
        <h2>桐生という街を<br>あなたと創る</h2>
        <h3>ファンディング桐生は<br class="sp">地域密着型クラウドファンディングです</h3>
        <div
          class="home__top__title__text"
          v-if="allSales">
          <p><span class="home__top__boder">
            <span class="home__top__boder__big">{{ addComma(allSales.lot) }}</span>人</span>が愛する桐生のために<br class="sp"><span class="home__top__boder"><span class="home__top__boder__huge">{{ addComma(allSales.amount) }}</span>円</span>サポートしました。</p>
        </div>
      </div>
      <div class="home__top__mainLogo">
        <img src="../assets/images/company/times.png" alt="タイムス">
        <div  class="home__top__mainLogo__cross"></div>
        <img src="../assets/images/company/cicac.svg" alt="CICAC">
      </div>
    </div>

    <div
      class="home__contents"
      id="home__contents"
      v-if="helper.master.projects">

      <!--プロジェクト期間の終了していないものを優先的に上に表示する-->
      <SortedProjects
        v-if="helper.master.projects"
        :condition="openedProjects"
        :projects="helper.master.projects" />
      <SortedProjects
        v-if="helper.master.projects"
        :condition="closedProjects"
        :projects="helper.master.projects" />
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';
import SortedProjects from '@/components/parts/Project/SortedProjects.vue';

export default {
  name: 'Home',
  mixins: [cf],
  components: {
    SortedProjects,
  },
  data() {
    return {
      observer: null,
      allSales: null,
      openedProjects: [],
      closedProjects: [],
    };
  },
  created() {
    if (this.helper.master.projects) {
      this.getAllSalesData();
      this.checkActiveProjects();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putmaster') {
          this.getAllSalesData();
          this.checkActiveProjects();
        }
      });
    }
  },
  computed: {
    ...mapState(['helper']),
  },
  mounted() {
    this.setIntersectionObserver();
  },
  beforeUnmount() {
    this.unobserve();
  },
  methods: {
    ...mapActions('layout/header', ['changeIsTransparent']),
    setIntersectionObserver() {
      const options = {
        rootMargin: '-56px 0px 0px 0px',
      };
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(({ isIntersecting }) => {
          this.changeIsTransparent(isIntersecting);
        });
      }, options);
      const homeTop = this.$refs.homeTop;
      this.observer.observe(homeTop);
    },
    unobserve() {
      this.changeIsTransparent(false);
      const homeTop = this.$refs.homeTop;
      this.observer.unobserve(homeTop);
    },
    connectTest() {
      this.axios({
        method: 'GET',
        url: '/v1/connection/test',
        params: {},
      })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    /** メインビジュアル上に表示する全売上データ */
    getAllSalesData() {
      this.axios({
        method: 'GET',
        url: '/v1/sales/get/allSum',
        params: {},
      })
        .then((response) => {
          const data = response.data;
          this.allSales = data.result;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /**
     * プロジェクトの開催・終了をチェック
     */
    checkActiveProjects() {
      this.openedProjects = this.helper.master.projects.filter((openedProject) => this.getRemainingToday(openedProject.period) > 0);
      this.closedProjects = this.helper.master.projects.filter((closedProject) => this.getRemainingToday(closedProject.period) < 1);
      // this.openedProjects.sort((a, b) => moment(b.period) - moment(a.period));
      // this.closedProjects.sort((a, b) => moment(b.period) - moment(a.period));
    },
  },
};
</script>
