<template>
  <section>
    <div class="comment-tab_content" :class="$style.content">
      <ul
        class="comment-list"
        v-if="comments.length">
        <li
          v-for="(comment, index) in comments"
          :key="index">
          <div class="comment_icon_wrap">
            <div class="comment_icon">
              <div
                class="comment_icon_content"
                :style="{ backgroundImage: `url(${getIconURL(comment.userDetail)})`}"></div>
            </div>
          </div>

          <div class="comment_text">
            <div class="comment_text_wrap">
              <p class="comment_text_name">{{ displayName(comment) }}</p>
              <p class="comment_text_date">{{ formatTimestamp(comment.updated_at, 'YYYY.MM.DD') }}</p>
              <button
                class="comment_text_editBtn"
                v-on:click="startEditComment(comment.id, comment.comment)"
                v-if="user.login.isLogin
                  && comment.userDetail
                  && comment.userDetail.id === user.id
                  && !flag.editing">編集</button>
              <button
                class="comment_text_editBtn"
                v-on:click="updateComment(comment.id)"
                v-if="user.login.isLogin && flag.editing && editingComment === comment.id">登録</button>
            </div>

            <p v-if="editingComment != comment.id">{{ comment.comment }}</p>

            <form class="form" action="" v-if="flag.editing && editingComment === comment.id">
              <div class="form-contents">
                  <div class="form-contents">
                    <dl class="form-group">
                      <dd class="detail text">
                        <div class="form-parts">
                          <input
                            id="comment"
                            type="text"
                            name="comment"
                            :value="comment.comment"
                            :disabled="flag.confirm"
                            v-on:change="changeValue">
                          <input type="text" name="dummy" style="display:none;">
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
            </form>
          </div>
        </li>
      </ul>

      <p v-else-if="flag.apiLoaded">ご支援・応援コメントをぜひお願いいたします！</p>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'CommentTab',
  mixins: [cf],
  props: ['product'],
  data() {
    return {
      comments: [],
      urls: [],
      updatedComments: null,
      editingComment: null,
      flag: {
        editing: false,
        apiLoading: false,
        apiLoaded: false,
      },
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    this.getComments();
  },
  methods: {
    getComments() {
      // 重複読み込みの制御
      if (this.flag.apiLoading) return;
      this.flag.apiLoading = true;
      // 初期化
      this.comments = [];
      const params = {
        product_id: this.product.master.id,
        withUserData: 1,
      };

      this.axios({
        method: 'GET',
        url: '/v1/comment/get/subscription/list',
        params,
      })
        .then((response) => {
          const res = response.data.comments.data;
          if (res.length) this.comments = res;
          else this.comments = [];
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.apiLoading = false;
          this.flag.apiLoaded = true;
        });
    },

    /** iconURLの有無でデフォルト画像と切り替え */
    getIconURL(user) {
      const iconDefault = '/img/personalSponsors/icon_dummy.png';
      const result = user.urls.icon.length ? user.urls.icon[0].url : iconDefault;
      return result;
    },

    startEditComment(id, comment) {
      this.updatedComments = comment;
      this.editingComment = id;
      this.flag.editing = true;
    },

    changeValue(e) {
      this.updatedComments = e.target.value;
    },

    /** コメント更新 */
    updateComment(commentID) {
      const data = {
        id: commentID,
        comment: this.updatedComments,
      };

      this.axios({
        method: 'POST',
        url: '/v1/comment/set/subscription/update',
        data,
      })
        .then(() => {
          this.updatedComments = null;
          this.editingComment = null;
          this.flag.editing = false;

          this.getComments();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
        });
    },

    // 表示名
    displayName(comment) {
      if (!comment.userDetail) return;
      let result = null;
      if (comment.salesDetail) { // comment.sales_id指定済
        if (comment.salesDetail.displayname) {
          result = comment.salesDetail.displayname.displayname;
        } else {
          result = comment.userDetail.displayname;
        }
      } else if (comment.userDetail.displayname) { // comment.sales_id未指定
        result = comment.userDetail.displayname;
      } else {
        result = '匿名';
      }
      return result;
    },
  },
};
</script>

<style module>
.comingsoon {
  margin-top: 30px;
  font-size: 30px;
  text-align: center;
  font-style: italic;
}

@media screen and (max-width: 576px) {
  .comingsoon {
    font-size: 23px;
  }
}

@media screen and (max-width: 369px) {
  .comingsoon {
    font-size: 21px;
  }
}
</style>
