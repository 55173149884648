<template>
  <div v-if="completion">
    <template v-if="!flag.notFound && product">
      <first-view :product="product" />
      <main-contents :product="product" />
    </template>
    <not-found v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';
import NotFound from '@/views/404.vue';
import { FirstView, MainContents } from '@/components/pages/projects/subscriptions';

export default {
  name: 'subscription-detail',
  mixins: [cf],
  components: {
    FirstView,
    MainContents,
    NotFound,
  },
  data() {
    return {
      completion: false,
      product: {
        stripe: {
          data: null,
          prices: [],
          tax: null,
        },
        master: null,
        slug: null,
        productId: null,
      },
      flag: {
        notFound: false,
      },
    };
  },
  created() {
    if (!this.$route.params.slug) {
      this.flag.notFound = true;
      return;
    }
    this.product.slug = this.$route.params.slug;
    // master(yaml)のサブスクデータ取得
    if (Object.keys(this.helper.master).length) {
      this.getMasterSubscriptionData();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putmaster') {
          this.getMasterSubscriptionData();
        }
      });
    }
  },
  computed: {
    ...mapState(['helper']),
  },
  watch: {
    // productId() {
    //   this.getMasterSubscriptionData();
    // },
  },
  methods: {
    /** DB（yaml）のサブスクデータ取得 */
    getMasterSubscriptionData() {
      this.product.master = this.helper.master.subscriptions.find((product) => product.name === this.product.slug);
      if (!this.product.master) {
        // 一致するスラッグが存在しない場合はNotFound
        this.flag.notFound = true;
        this.completion = true;
        return;
      }

      this.product.productId = this.product.master?.product_id[this.helper.env.name] || null;

      // stripeのサブスクデータ取得
      this.getStripeSubscriptionData();
      // 支援済みサポーター取得
      this.getSupporters();
    },

    /** stripeのサブスクデータ取得 */
    getStripeSubscriptionData() {
      this.completion = false;
      this.flag.notFound = false; // watchの404更新対策
      if (!this.product.productId) {
        // 対象データが見つからない
        this.flag.notFound = true;
        this.flag.completion = false;
        return;
      }

      this.flag.loader = true;

      this.axios({
        method: 'GET',
        url: 'v1/stripe/subscription/get/detail',
        params: {
          subscription_id: this.product.productId,
        },
      })
        .then((response) => {
          const res = response.data;
          this.product.stripe.data = res.product;
          this.product.stripe.prices = res.prices.data;
          this.product.stripe.tax = res.tax;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loader = false;
          this.completion = true;
        });
    },

    /** 該当サブスクの支援者取得 */
    getSupporters() {
      this.axios({
        method: 'GET',
        url: 'v1/stripe/subscription/get/supporters',
        params: {
          product_id: this.product.productId,
        },
      })
        .then((response) => {
          const res = response.data;
          this.product.supporters = res.supporters;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>
