<template>
  <div>
    <div
      class="mainCard home__contents__card"
      v-for="project in condition "
        :key="project.id">
      <div class="mainCard__images">
        <img
          :src="project.urls.main"
          :alt="project.title"
          class="mainCard__images__bg"
        />
      </div>
      <div class="mainCard__detail">
        <h4 class="mainCard__title" v-html="project.title"></h4>
        <p class="mainCard__text" v-html="project.description"></p>
        <div class="mainCard__detailBtn">
          <router-link :to="`/projects/${project.slug}/#mainContents_wrapper`"
            >リターンを見る</router-link
          >
          <router-link :to="`/projects/${project.slug}/`"
            >もっと詳しく</router-link
          >
        </div>
        <div class="mainCard__info">
          <div class="mainCard__info__number">
            <div class="mainCard__info__disc">
              <p class="mainCard__info__label">支援総額</p>
              <p class="mainCard__info__total" v-if="salesTotal[project.id]">
                {{ addComma(salesTotal[project.id].amount) }}<span>円</span>
              </p>
              <p class="mainCard__info__goal">
                目標金額 : {{ addComma(project.target) }}円
              </p>
            </div>
            <div class="mainCard__info__disc">
              <p class="mainCard__info__label">支援者</p>
              <p class="mainCard__info__sub" v-if="salesTotal[project.id]">
                {{ addComma(salesTotal[project.id].lot) }}<span>人</span>
              </p>
            </div>
            <div
              class="mainCard__info__disc"
              v-if="isRemaining(project.period)"
            >
              <p class="mainCard__info__label">募集終了まで残り</p>
              <p class="mainCard__info__sub">
                {{ getRemainingTimeNum4Display(project.period) }}<span>{{ getRemainingTimeUnit4Display(project.period) }}</span>
              </p>
            </div>
            <div
              class="mainCard__info__disc mainCard__info__sub"
              v-else-if="!isRemaining(project.period)"
            >
              終了
            </div>
          </div>
          <div class="mainCard__info__graph">
            <div class="graph" v-if="salesTotal[project.id]">
              <progress-circle
                :progress="
                  getPercentage(salesTotal[project.id].amount, project.target)
                "
              />
            </div>
          </div>
        </div>
        <div class="mainCard__info">
          <router-link class="mainCard__btn" :to="`/projects/${project.slug}/`"
            >支援する</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProgressCircle } from '@/components/parts/Project';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'SortedProjects',
  mixins: [cf],
  components: {
    ProgressCircle,
  },
  props: {
    condition: {
      type: Array,
    },
    projects: {
      type: Array,
    },
  },
  data() {
    return {
      salesTotal: {},
    };
  },
  created() {
    this.getProjectSalesData();
  },

  methods: {
    /** 初期実装対応:プロジェクト毎の支援状況 */
    async getProjectSalesData() {
      // loop内でawaitを使用するためPromis.all(arr.map)を使用
      await Promise.all(
        this.projects.map(async (project) => {
          this.axios({
            method: 'GET',
            url: '/v1/sales/get/sum',
            params: {
              type: 'project',
              id: project.id,
            },
          })
            .then((response) => {
              const data = response.data;
              this.salesTotal[project.id] = data.result;
            })
            .catch((error) => {
              if (error.response) console.log(error.response.data);
              else console.log(error);
            });
        }),
      );
    },
  },
};

</script>
