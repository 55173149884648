<template>
  <div v-if="product.stripe" :class="$style.wrapper">
    <contents-container>
      <hero-slider v-if="product.master.slider" :images="product.master.slider" />

      <div :class="$style.product">
        <div :class="$style.header">
          <h1 :class="$style.title">{{ product.stripe.data.name }}</h1>
          <p :class="$style.description" v-html="product.stripe.data.description"></p>
          <div v-if="product.master.ImgUrlLink">
            <a
              :href="product.master.ImgUrlLink"
              target="_blank">
              <img :class="$style.honorImg" :src="product.master.honorImgUrl" :alt="product.stripe.data.name">
            </a>
          </div>
          <div v-else-if="product.master.honorImgUrl">
            <img :class="$style.honorImg" :src="product.master.honorImgUrl" :alt="product.stripe.data.name">
          </div>
        </div>

        <div :class="$style.progress">
          <rounded-box :gutter="'lg'" :radius="'lg'">
            <div v-if="salesTotal" :class="$style.progress__inner">
              <dl :class="$style.total">
                <dt :class="$style.label">支援総額</dt>
                <dd :class="`${$style.value} ${$style.lg}`">
                  {{ addComma(salesTotal.amount) }}
                  <span :class="$style.valueUnit">円</span>
                </dd>
                <p :class="$style.target">目標金額 : {{ addComma(product.master.target) }}円</p>
              </dl>
              <div :class="$style.progress__circle">
                <progress-circle
                  :progress="getPercentage(salesTotal.amount, product.master.target)"/>
              </div>
            </div>

            <div v-if="salesTotal" :class="$style.progress__inner">
              <dl :class="$style.patron">
                <dt :class="$style.label">支援者</dt>
                <dd :class="$style.value">
                  {{ addComma(salesTotal.lot) }}人
                </dd>
              </dl>
            </div>
            <button v-scroll-to="{
              el: '#returnList',
              offset: -100,
            }" :class="$style.button">プランをみる</button>
          </rounded-box>
        </div>
      </div>
    </contents-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';
import { ContentsContainer, RoundedBox } from '@/components/parts/Container';
import { ProgressCircle } from '@/components/parts/Project';
import HeroSlider from './HeroSlider.vue';

export default {
  name: 'FirstView',
  mixins: [cf],
  props: ['product'],
  components: {
    ContentsContainer,
    RoundedBox,
    ProgressCircle,
    HeroSlider,
  },
  data() {
    return {
      salesTotal: null,
    };
  },
  created() {
    this.getSubscriptionSalesData();
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    /** 該当のサブスクを支援済みか判定 */
    isDisabled(priceId) {
      return this.user.subscriptions.planIds.includes(priceId);
    },

    subscriptionSubmit(data) {
      const ref = { path: this.$router.options.history.location };
      cf.saveLocalStorage({ loginRef: JSON.stringify(ref) }, 'fk');
      if (!this.user.login.isLogin) {
        const args = {
          modalName: 'loginOnly',
          data: {
            title: 'サブスクリプションのお申し込み',
            description: 'アカウントをお持ちの方はログインページよりログインをお願いします。<br>アカウントをお持ちでない方は無料会員登録から作成してください。',
          },
        };
        this.$store.dispatch('modal/messages/showModal', args, { root: true });
        return;
      }
      if (!this.user.cards.cards.length) {
        const args = {
          modalName: 'formSubscription',
          data,
        };
        this.$store.dispatch('modal/contents/showModal', args, { root: true });
      } else {
        const args = {
          modalName: 'paymentSubscription',
          data,
        };
        this.$store.dispatch('modal/contents/showModal', args, { root: true });
      }
    },

    /** サブスクの合計売上額を取得 */
    getSubscriptionSalesData() {
      this.axios({
        method: 'GET',
        url: '/v1/sales/get/subscription/sum',
        params: {
          product_id: this.product.productId,
        },
      })
        .then((response) => {
          const res = response.data;
          this.salesTotal = res.result;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>

<style module lang="scss">
.wrapper {
  background-color: #e5e5e5;
  padding-bottom: 80px;
}

.product {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 32px;
}

.header {
  flex-basis: 474px;
  flex-shrink: 3;
  flex-grow: 1;
}

.title {
  font-size: 40px;
  line-height: 1.6;
  font-weight: 700;
}

.description {
  margin-top: 24px;
  font-size: 20px;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.64);
}

.honorImg {
  margin-top: 2em;
  max-height: 10em;
  max-width: 100%;
}

.socialList {
  display: flex;
  margin-top: 32px;
}

.progress {
  flex-basis: 474px;
  margin-left: calc(min(40px, 10px + 3vw));
  flex-shrink: 2;
  flex-grow: 1;
}

.progress__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress__inner + .progress__inner {
  margin-top: 24px;
}

.progress__circle {
  width: 100%;
  max-width: 160px;
  margin-left: 16px;
}

.target {
  margin-top: 12px;
  font-size: 16px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.64);
  margin-bottom: .5em;
}

.label {
  display: block;
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
  color: #333;
}

.value {
  display: flex;
  align-items: baseline;
  margin-top: 12px;
  font-size: clamp(24px, calc(4px + 3.5vw), 40px);
  line-height: 1;
  letter-spacing: -0.5px;
  font-weight: bold;
  white-space: pre-line;
  span {
    font-size: 16px;
    font-weight: normal;
  }
  &.lg {
    font-size: clamp(32px, calc(4px + 3.5vw), 48px);
  }
}

.priceDescription {
  margin-top: 1rem;
  font-size: 14px;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.64);
}

.valueUnit {
  margin-left: 8px;
  font-size: 24px;
  line-height: 1;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  margin-top: 32px;
  border: none;
  border-radius: 32px;
  background-color: #000;
  color: #fff;
  appearance: none;
  box-shadow: none;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  &:disabled {
    background-color: rgba(0,0,0,0.2);
  }
}

@media screen and (max-width: 1023px) {
  .title {
    font-size: 30px;
  }

  .description {
    margin-top: 20px;
    font-size: 16px;
  }

  /* .progress__inner + .progress__inner {
    margin-top: 32px;
  } */

  .target {
    font-size: 12px;
  }

  .button {
    height: 48px;
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    padding-bottom: 24px;
  }

  .product {
    display: block;
  }

  .header {
    width: 100%;
  }

  .socialList {
    margin-top: 24px;
  }

  .socialList__item + .socialList__item {
    margin-left: 16px;
  }

  @media screen and (max-width: 1023px) {
    .title {
      font-size: 30px;
    }
    .description {
      margin-top: 20px;
      font-size: 16px;
    }
    .progress__inner + .progress__inner {
      margin-top: 32px;
    }
    .target {
      font-size: 12px;
    }
    .button {
      height: 48px;
      font-size: 18px;
    }
  }


  @media screen and (max-width: 767px) {
    .firstView {
      padding-bottom: 24px;
    }
    .project {
      display: block;
    }
    .header {
      width: 100%;
    }
    .socialList {
      margin-top: 24px;
    }
    .socialList__item + .socialList__item {
      margin-left: 16px;
    }
    .progress {
      width: 100%;
      max-width: 474px;
      margin: 24px auto 0;
    }
    .content {
      margin-top: 4em;
    }
    .progress__circle {
      max-width: 88px;
    }
  }
}
</style>
